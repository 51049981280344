import React from "react";
import Layout from "../components/layout";
import { Breadcrumbs } from "../components/styleguide";

const AdditionalInfo = ({ pageContext }) => {
  const { data, common, breadcrumbs } = pageContext;

  return (
    <Layout data={common} title={data.seoTitle} description={data.seoDescription} hrefLang={data.hrefLang}>
      <div className='static-page-content'>
        <div className='main-container'>
          <Breadcrumbs data={breadcrumbs} lang={common.lang} />
          <header>
            <h1>{data.title}</h1>
          </header>
          <div className='markdown-content'>
            {data.dodatkowe_informacje_sekcja.length > 0 &&
              data.dodatkowe_informacje_sekcja.map(({ id, nazwa_sekcji, sekcja_pliki }) =>
                <React.Fragment key={id}>
                  <h2>{nazwa_sekcji}</h2>
                  {sekcja_pliki.length > 0 &&
                    <ul className="additional-page-list">
                      {sekcja_pliki.map(({ url_pliku, id, nazwa_pliku }) =>
                        <li key={id}>
                          <span>{nazwa_pliku}</span>
                          <a href={url_pliku} target='_blank' rel='noopener noreferrer' download className='btn btn-md fill'>
                            {common.translations[0].download}
                          </a>
                        </li>
                      )}
                    </ul>
                  }
                </React.Fragment>)
            }
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdditionalInfo;
